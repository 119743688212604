<template>
    <div>
        <el-form ref="queryForm" class="query-box"  v-model="queryParams" label-width="68px">
            <el-row :gutter="20">
				<el-col :span="6">
                    <el-form-item label="渠道" label-width="40px">
                        <el-select v-model="queryParams.channelNo" size="small" placeholder="请选择渠道" style="width: 100%">
                            <el-option label="全部" value=""></el-option>
                            <el-option v-for="(item,index) in acqChannelList" :label="item.name" :value="item.channelNo" :key="index"></el-option>
                        </el-select>
                    </el-form-item>
				</el-col>
				<el-col :span="6">
					<el-form-item label="政策编号">
                        <el-input v-model="queryParams.policyNo"
                                  clearable
                                  placeholder="请输入政策编号"
                                  size="small" @keyup.enter.native="handleQuery"/>
                    </el-form-item>
				</el-col>
				<el-col :span="6">
                    <el-form-item label="政策名称">
                        <el-input v-model="queryParams.policyName"
                                  clearable
                                  placeholder="请输入政策名称"
                                  size="small" @keyup.enter.native="handleQuery"/>
                    </el-form-item>
				</el-col>
			</el-row>
        </el-form>
        <el-row :gutter="10" class="mb8">

            <el-col :span="1.5">
                <el-button type="primary" icon="el-icon-search" size="mini"
                           @click="handleQuery">
                    查询
                </el-button>
                <el-button type="primary" size="mini"
                           @click="handleAdd"
                           v-permission="'POLICY_INFO_QUERY'">
                    新增
                </el-button>
                <el-button icon="el-icon-refresh" size="mini" @click="handleResetQuery" v-permission="'POLICY_INFO_QUERY'">重置</el-button>
            </el-col>
        </el-row>

    </div>
</template>

<script> 
    import {PolicyApi,TerminalApi} from '@/api';
    export default {
        name: "ToolBar",
        props: {
            dicts: {
                type: Object,
                default: {}
            }
        },
        data() {
            return {
                queryParams: {},
                createDateRange: [],
                updateDateRange: [],
                showCustomForm: false,
                acqChannelList:[]
            }
        },
        
    async mounted() {
		const result = await TerminalApi.acqChannel.listAllChannel();
		this.acqChannelList = (result && result.data) || [];
	},
        methods: {
            handlerCustomFormOnclose() {
                this.showCustomForm = false;
                this.commitChange();
            },
            handleQuery() {
                this.commitChange();
            },
            handleResetQuery(){
                this.queryParams = {};
                this.createDateRange = [];
                this.updateDateRange = [];
                this.commitChange();
            },
            handleAdd(){
                this.$router.push({
                    name:'ratePolicyInsert'
                })
            },
            handleExport() {
                this.$confirm("确定导出政策",
                        "警告", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning"
                }).then(async () => {
                    this.dealWithQueryParams();
                    let result = (await BusinessApi.policyInfo.export(this.queryParams));
                    if (result.success) {
                        this.downloadFile(result.data);
                    }
                });
            },
            dealWithQueryParams() {
                let [startCreateTime, endCreateTime] = this.createDateRange;
                this.queryParams.startCreateTime = startCreateTime && (startCreateTime + ' 00:00:00');
                this.queryParams.endCreateTime = endCreateTime && (endCreateTime + ' 23:59:59');

                let [startUpdateTime, endUpdateTime] = this.updateDateRange;
                this.queryParams.startUpdateTime = startUpdateTime && (startUpdateTime + ' 00:00:00');
                this.queryParams.endUpdateTime = endUpdateTime && (endUpdateTime + ' 23:59:59');
            },
            commitChange() {
                this.dealWithQueryParams();
                let params = {...this.queryParams};
                this.$emit('on-change', params);
            },
        }
    }
</script>

<style scoped>

</style>
